import * as React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <>
    <Helmet>
      <body className="error-404 has-background-white" />
    </Helmet>
    <Layout>
      <SEO title="404: Not found" />
      <section className="section content">
        <h1 className="title is-1">Well, that was kind of unexpected</h1>
        <Link to="/" className="button is-transparent">
          Go back
        </Link>
      </section>
    </Layout>
  </>
);

export default NotFoundPage;
